import {useEffect} from 'react';

/**
 * Custom hook permettant de mettre a jour le title description
 * @returns
 */
const usePageMeta = (title:string, description:string) => {
  const defaultTitle = "Poubelles";
  const defaultDesc = "";

  useEffect(() => {
    document.title = title || defaultTitle;
    document.querySelector("meta[name='description']")?.setAttribute("content", description || defaultDesc);
    document.querySelector("meta[property='og:title']")?.setAttribute("content", title || defaultTitle);
  }, [defaultTitle, title, defaultDesc, description]);

};

export default usePageMeta;
