const SET_LOADING_STATE = 'SET_LOADING_STATE';
const SET_ALLDATAS = 'SET_ALLDATAS';
const SET_USERDATAS = 'SET_USERDATAS';
const SET_DEDUCTEDUSERDATAS = 'SET_DEDUCTEDUSERDATAS';
const SET_USERZONING = 'SET_USERZONING';
const SET_INFOREAD = 'SET_INFOREAD';
const SET_LAST_INFO = 'SET_LAST_INFO';
const REDUCER_ACTIONS = {
  SET_LOADING_STATE,
  SET_ALLDATAS,
  SET_USERDATAS,
  SET_DEDUCTEDUSERDATAS,
  SET_USERZONING,
  SET_INFOREAD,
  SET_LAST_INFO
}
export default REDUCER_ACTIONS;
