import Page from './calendar';


// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext                from '../../datas/contexts/GlobalContext';

const CalendarInContext = () => {
  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {userDatas} = state;

  return <Page
            plastiques={userDatas?.plastiques?.days}
            verre={userDatas?.verre?.days}
            vegetaux={userDatas?.vegetaux?.days} />;
};

export default CalendarInContext;
