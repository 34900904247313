import React from 'react';

import {T_LAST_INFO} from '../../datas/contexts/state/interfaces';
import INTERFACE from './I_NewsBox';

interface I_PROPS {
  lastInfo?: T_LAST_INFO;
}

function NewsBox(props: I_PROPS) {
  const {lastInfo} = props;

  if (!lastInfo)
    return null;

  return <INTERFACE {...lastInfo}/>;
}
export default NewsBox;
