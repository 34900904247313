/**
 * Renvoie le code class correspond à l'imminence du prochain ramassage
 * * dans moins de 2 jours : "imminent"
 * * dans moins d'une semaine : "soon"
 * * sinon, vide
 * @param currentDate date courante calée sur 00hh00mm00
 * @param nextDate date du prochain ramassage calée sur 00hh00mm00
 * @returns
 */
const getImminenceClassName = (currentDate: Date, nextDate: Date) => {
  var ecartInDays = ((nextDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
  if(ecartInDays < 2)
    return 'imminent';
  if(ecartInDays <= 7)
    return 'soon';
  return '';
};

/**
 * Renvoie le libellé indiquant dans combien de temps sera le prochain ramassage
 * @param currentDate date courante calée sur 00hh00mm00
 * @param nextDate date du prochain ramassage calée sur 00hh00mm00
 * @returns
 */
const getWaitingForNextLabel = (currentDate: Date, nextDate: Date):string => {
  var ecartInDays = ((nextDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
  if(ecartInDays < 1)
    return 'Aujourd\'hui';
  if(ecartInDays < 2)
    return 'Demain';
  if(ecartInDays < 3)
    return 'Après-demain';
  if(ecartInDays < 4)
    return '3j';
  if(ecartInDays < 5)
    return '4j';
  if(ecartInDays < 6)
    return '5j';
  if(ecartInDays < 7)
    return '6j';
  if(ecartInDays < 8)
    return '7j';
  if(ecartInDays < 14)
    return '+7j';
  if(ecartInDays < 21)
    return '+2sem';
  if(ecartInDays < 31)
    return '+3sem';
  return '+1mois';
};

export {getImminenceClassName,getWaitingForNextLabel};
