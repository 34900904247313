import React from 'react';

// import './warning.scss';

interface I_PROPS {
  loadingStatus?: string;
  selectedCityLabel?: string;
  isZoningComplete?: boolean;
  openModal?: any;
}

function ZoningModalButton({loadingStatus,selectedCityLabel,isZoningComplete,openModal} : I_PROPS) {

  if (!loadingStatus) {
    return null;
  }

  return (
    <div className="targetButton" onClick={openModal}>
      <span>{ selectedCityLabel }</span>
      {!isZoningComplete ? <span className="highlight">Cliquez pour sélectionner votre zone</span> : null}
    </div>
  );
}
export default ZoningModalButton;
