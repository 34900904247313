import Page from './home';

import {T_ZONING,T_USER_DATAS,T_DEDUCTED_USER_DATAS,T_LAST_INFO} from '../../datas/contexts/state/interfaces';

// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext                from '../../datas/contexts/GlobalContext';
import REDUCER_ACTIONS from '../../datas/contexts/reducers/actions';

const HomeInContext = () => {
  // valeurs en context
  const {state,dispatch} = useContext(GlobalContext);
  const {loadingStatus, allDatas,lastInfo,infoRead,userDatas,userZoning} = state;

  const setUserDatasDispatch = (userDatas:T_USER_DATAS|undefined) => {
    dispatch({type:REDUCER_ACTIONS.SET_USERDATAS,payload:{userDatas}});
  };
  const setDeductedUserDispatch = (deductedUserDatas:T_DEDUCTED_USER_DATAS) => {
    dispatch({type:REDUCER_ACTIONS.SET_DEDUCTEDUSERDATAS,payload:{deductedUserDatas}});
  };
  const setZoningDispatch = (userZoning:T_ZONING) => {
    dispatch({type:REDUCER_ACTIONS.SET_USERZONING,payload:{userZoning}});
  };
  const setInfoReadDispatch = (infoRead:T_LAST_INFO|undefined) => {
    dispatch({type:REDUCER_ACTIONS.SET_INFOREAD,payload:{infoRead}});
  };

  return <Page
            loadingStatus={loadingStatus}
            allDatas={allDatas}
            lastInfo={lastInfo}
            infoRead={infoRead}
            userDatas={userDatas}
            userZoning={userZoning}
            setZoningDispatch={setZoningDispatch}
            setUserDatasDispatch={setUserDatasDispatch}
            setDeductedUserDispatch={setDeductedUserDispatch}
            setInfoRead={setInfoReadDispatch} />;
};

export default HomeInContext;
