import Page from './documents';


// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext                from '../../datas/contexts/GlobalContext';

const DocumentsInContext = () => {
  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {userDatas} = state;

  const userDocuments = userDatas?.documents;

  return <Page userDocuments={userDocuments} />;
};

export default DocumentsInContext;
