import REDUCER_ACTIONS from './actions';

const reducer = (
  state: any,
  action: any
) => {
	if (action.type === REDUCER_ACTIONS.SET_LOADING_STATE) {
		const {status} = action.payload;
    console.log('reducer - SET_LOADING_STATE',{status});
		return {...state,loadingStatus:status};
	}
	if (action.type === REDUCER_ACTIONS.SET_ALLDATAS) {
		const {cityID,data} = action.payload;
    console.log('reducer - SET_ALLDATAS',{cityID,data});
		const newState = {...state};
    newState.allDatas[cityID] = data
    return newState
	}
	if (action.type === REDUCER_ACTIONS.SET_USERDATAS) {
		const {userDatas} = action.payload;
    console.log('reducer - SET_USERDATAS',{userDatas});
		return {...state,userDatas};
	}
	if (action.type === REDUCER_ACTIONS.SET_DEDUCTEDUSERDATAS) {
		const {deductedUserDatas} = action.payload;
    console.log('reducer - SET_DEDUCTEDUSERDATAS',{deductedUserDatas});
		return {...state,deductedUserDatas};
	}
	if (action.type === REDUCER_ACTIONS.SET_USERZONING) {
		const {userZoning} = action.payload;
    console.log('reducer - SET_USERZONING',{userZoning});
		return {...state,userZoning};
	}
	if (action.type === REDUCER_ACTIONS.SET_INFOREAD) {
		const {infoRead} = action.payload;
    console.log('reducer - SET_INFOREAD',{infoRead});
		return {...state,infoRead};
	}
	if (action.type === REDUCER_ACTIONS.SET_LAST_INFO) {
		const {lastInfo} = action.payload;
    console.log('reducer - SET_LAST_INFO',{lastInfo});
		return {...state,lastInfo};
	}
	// return state;
	throw new Error(`No matching "${action.type}" action type`);
};

export default reducer;
