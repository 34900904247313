import Component from './SelectCity';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';

// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext                from '../../datas/contexts/GlobalContext';

// --- Services ----
import { extractZoningsCities } from '../../datas/helpers';

const ComponentInMemo = memo(Component);

const ComponentInContext = ({onSelectCity}:{onSelectCity:any}) => {

  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {allDatas,userZoning} = state;

  const cities = extractZoningsCities(allDatas);

  return <ComponentInMemo
            cities={cities}
            userCitySelected={userZoning.city}
            onSelect={onSelectCity} />;
};

export default memo(ComponentInContext);
