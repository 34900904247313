import React from 'react';

import {T_CITIES_NAMES} from '../../services/I_ZONINGS_MAP';

// import NextBlock  from "../nextBlock";
// import './NextBlock.scss';

interface I_PROPS {
  cities?: T_CITIES_NAMES;
  userCitySelected?: string;
  onSelect?: any;
}

function SelectCity({cities,userCitySelected,onSelect} : I_PROPS) {

  if (!cities || Object.keys(cities).length < 1)
    return null;

  return (
    <div className="cities">
      { Object.keys(cities).map((cityKey) => {
        const isSelected = userCitySelected && userCitySelected === cityKey;
        return (
          <div key={cityKey}
            className={'cityModalButton ' + (isSelected ? 'selected' : '')}
            onClick={(e) => onSelect(cityKey)}>
              { cities[cityKey] }
          </div>
        );
      })}
    </div>
  );
}
export default SelectCity;
