import React, {useState,useEffect,useCallback} from 'react';

// --- Hooks ----
// Custom hook de gestion du localStorage (cache)
import useMetas from '../../datas/hooks/useMetas';

import Menu     from "../../components/menu";
import Version  from "../../components/version";
import Bubble  from "../../components/bubble";
import ModalInfo  from "../../components/modalInfo";
import ModalZoning  from "../../components/modalZoning";
import Warning  from "../../components/warning";
import Now  from "../../components/now";
import NextBlocks  from "../../components/nextBlocks";
import ZoningModalButton from '../../components/zoningModalButton';

import {T_CITIES_ALL_DATAS,T_USER_DATAS,T_DEDUCTED_USER_DATAS,T_ZONING,T_LAST_INFO} from '../../datas/contexts/state/interfaces';

import './home.scss';
import { setInfoReadInLocalStorage } from '../../datas/helpers/localStorage';

function isInfoBubbleActive(lastInfo:T_LAST_INFO|undefined,infoRead:T_LAST_INFO|undefined) {
  if (lastInfo === undefined) {
    return false;
  }
  if (infoRead === undefined) {
    return true;
  }
  return lastInfo.id !== infoRead.id;
}

interface I_PROPS {
  loadingStatus?: string;
  allDatas?: T_CITIES_ALL_DATAS;
  lastInfo?: T_LAST_INFO;
  infoRead?: T_LAST_INFO;
  userDatas?: T_USER_DATAS;
  userZoning?: T_ZONING;

  setZoningDispatch: (userZoning:T_ZONING) => void;
  setUserDatasDispatch: (userDatas:T_USER_DATAS|undefined) => void;
  setDeductedUserDispatch: (deductedUserDatas:T_DEDUCTED_USER_DATAS) => void;
  setInfoRead: (infoRead:T_LAST_INFO|undefined) => void;
}

function Home(props: I_PROPS) {
  const [ modalOpened, setModalOpened ] = useState<boolean>(false); // modal de selection de la zone

  const [ nextBlocksClassAnim, setNextBlocksClassAnim ] = useState('');

  // valeurs récupérées du reducer state, placées en context, transmises via props
  const {lastInfo,infoRead,userDatas,userZoning} = props;
  const {setInfoRead} = props;

  // hook metas
  useMetas('Prochaines dates de collecte des déchets','Les prochaines dates de collecte des déchets au Perray-en-Yvelines et aux Essarts-le-roi (78)');

  // states pour l'info
  const [ infoBubbleActive, setInfoBubbleActive ] = useState<boolean>(isInfoBubbleActive(lastInfo,infoRead));
  const [ modalInfoOpened, setModalInfoOpened ] = useState<boolean>(false); // modal d'infos

  // TODO : charger infos generales en fetch get, pour que si les gens ont pas mis a jour, ils aient l'infos qd meme

  useEffect(() => {
    setTimeout(function(){
      setNextBlocksClassAnim('enter');
      setTimeout(function(){
        setNextBlocksClassAnim('stable');
      },300);
    },300);
  },[]);

  useEffect(() => {
    // faire une anim sur les blocks
    setNextBlocksClassAnim('bump');
    setTimeout(function(){
      setNextBlocksClassAnim('stable');
    },600);
  },[userZoning]);

  // on met a jour infoBubbleActive si lastInfo ou infoRead change
  useEffect(() => {
    setInfoBubbleActive(isInfoBubbleActive(lastInfo,infoRead));
  },[infoRead, lastInfo]);


  function openModal(_e: any){
    setModalOpened(true);
  }
  function closeModal(_e: any){
    setModalOpened(false);
  }



  // Ouvre la modal lastInfo,
  // enregistre en localStorage qu'on a vu cette info
  // On utilise useCallback pour ne pas redéfinir la fonction au changement de state de Home,
  // et ainsi éviter de re-render Bubble an modifiant ses props
  const openModalInfo = useCallback(
    (_e: any) => {
      // ouvre le modal
      setModalInfoOpened(true);
      // enregistre qu'on a lu cette info
      setInfoRead(lastInfo);
      // on le met en localStorage
      setInfoReadInLocalStorage(lastInfo);
    },
    [lastInfo, setInfoRead]
  );

  // Ferme la modale lastInfo
  // On utilise useCallback pour ne pas redéfinir la fonction au changement de state de Home,
  // et ainsi éviter de re-render ModalInfo an modifiant ses props
  const closeModalInfo = useCallback(
    (_e: any) => {
      setModalInfoOpened(false);
    },
    []
  );

  return (
    <div className="home">


      <Menu />

      <div className="dates">

        <div className="titles">
          <h1>POUBELLES</h1>
          <Now />
          <ZoningModalButton openModal={openModal} />
          <Warning text={userDatas?.warningCity} />
        </div>

        <NextBlocks
          nextBlocksClassAnim={nextBlocksClassAnim} />

      </div>

      <Version />

      { lastInfo ? <Bubble active={infoBubbleActive} onClick={openModalInfo}/> : null}

      { modalOpened ? <ModalZoning onClickClose={closeModal} /> : null }

      { modalInfoOpened ? <ModalInfo onClickClose={closeModalInfo} /> : null }
    </div>
  );
}
export default Home;
