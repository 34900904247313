// --- DataContext ----
// On utilise le Context API pour faire descendre les datas emarquées
// sur tout l'arbre de composant
import {createContext} from 'react';
import I_STATE from './state/interfaces';
import DEFAULT_STATE from './state/defaults';
interface I_CONTEXT {
  state: I_STATE;
  dispatch: any;
}
const INITIAL_CONTEXT:I_CONTEXT = {
  state: DEFAULT_STATE,
  dispatch: undefined,
};
const GlobalContext = createContext(INITIAL_CONTEXT);
export default GlobalContext;
// --- \GlobalContext ----
