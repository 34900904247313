import { T_LAST_INFO, T_ZONING } from "../contexts/state/interfaces";

const KEY_PREFIX = 'poubelles_';
const KEY_INFOREAD = 'inforead';
const KEY_ZONING = 'zoning';
const KEYS = {
  KEY_INFOREAD,
  KEY_ZONING
}

export const getInfoReadFromLocalStorage = (defaultValue:T_LAST_INFO|undefined) => {
  if (true) { // activation cache
    const lsValue = window.localStorage.getItem(KEY_PREFIX + KEYS.KEY_INFOREAD);
    // console.log('In localStorage is',lsValue,'.');
    if (lsValue === undefined || lsValue === null || lsValue === 'undefined' || lsValue === 'null' || lsValue === '') {
      console.log('inforead in localStorage is invalid, returned is',defaultValue,'.');
      return defaultValue;
    }
    const jsonValue = JSON.parse(lsValue);
    // console.log('inforead in localStorage is valid, returned is',jsonValue,'.');
    return jsonValue;
  }
  console.log('No cache, inforead value is',undefined,'.');
  return undefined;
}

export const setInfoReadInLocalStorage = (infoRead:T_LAST_INFO|undefined) => {
  if (true) { // activation cache
    if (infoRead) {
      window.localStorage.setItem(KEY_PREFIX + KEYS.KEY_INFOREAD, JSON.stringify(infoRead));
    }
    else {
      window.localStorage.removeItem(KEY_PREFIX + KEYS.KEY_INFOREAD);
    }
    console.log('Stored inforead',infoRead,'in localStorage.');
  }
};

export const getZoningFromLocalStorage = (defaultValue:T_ZONING) => {
  if (true) {
    const lsValue = window.localStorage.getItem(KEY_PREFIX + KEYS.KEY_ZONING);
    // console.log('zoning in localStorage is ',lsValue,'.',KEY_PREFIX + KEYS.KEY_ZONING);
    if (lsValue === undefined || lsValue === null || lsValue === 'undefined' || lsValue === 'null' || lsValue === '') {
      console.log('zoning in localStorage is invalid, returned is',defaultValue,'.');
      return defaultValue;
    }
    try{
      const jsonValue = JSON.parse(lsValue);
      // console.log('zoning in localStorage is valid, returned is',jsonValue,'.');
      return jsonValue;
    }catch(err){
      console.error('Cannot parse localStorage',KEY_PREFIX + KEYS.KEY_ZONING,'value, reset it...');
      window.localStorage.removeItem(KEY_PREFIX + KEYS.KEY_ZONING);
      return defaultValue;
    }
    // recuperation des anciennes valeurs
    // else {
    //   let storageDeprecatedCity = window.localStorage['poubelles_city_id'];
    //   if (storageDeprecatedCity && storageDeprecatedCity !== 'undefined') {
    //     S_Datas.currentZoning = {
    //       city: storageDeprecatedCity,
    //       divers: undefined,
    //       plastiques: undefined,
    //       verre: undefined,
    //       vegetaux: undefined
    //     };
    //     window.localStorage.removeItem(S_Datas.STORAGE_KEY_ZONING);
    //   }
    // }
  }
  // console.log('No cache, zoning value is [].');
  return [];
}

export const setZoningInLocalStorage = (zoning:T_ZONING) => {
  if (true) {
    if (zoning) {
      window.localStorage.setItem(KEY_PREFIX + KEYS.KEY_ZONING, JSON.stringify(zoning));
    }
    else {
      window.localStorage.removeItem(KEY_PREFIX + KEYS.KEY_ZONING);
    }
    console.log('Store state history',zoning,'in localStorage.');
  }
};
