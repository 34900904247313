import React, {useContext, useEffect} from 'react';

import './App.scss';

// --- GlobalContext ----
// On utilise le Context API pour faire descendre les datas emarquées
// sur tout l'arbre de composant
import GlobalContext from './datas/contexts/GlobalContext';
import REDUCER_ACTIONS from './datas/contexts/reducers/actions';

// --- Services ----
import GlobalService from './services/GlobalService';

// --- Hooks ----
// Custom hook de gestion du localStorage (cache)
import { fetchCityData } from './datas/fetchs/cities';
import { extractDeductedUserDatas, extractUserDatas } from './datas/helpers';
import { getInfoReadFromLocalStorage, getZoningFromLocalStorage } from './datas/helpers/localStorage';
import Routing from './Routing';
import { fetchNewsData } from './datas/fetchs/news';

const Initializer = () => {

  const {state: reducerState,dispatch: reducerDispatch} = useContext(GlobalContext);
  const {allDatas,userZoning} = reducerState;

  // services
  const globalService = GlobalService.getInstance();

  // On utilise useEffect pour lire les datas embarquées et les datas fetch une seule fois au mount du composant
  useEffect(() => {
    reducerDispatch({type:REDUCER_ACTIONS.SET_LOADING_STATE,payload:{status:'isLoading'}});

    // Lit le zoning
    const zoningInLocalStorage = getZoningFromLocalStorage({
      city: 'auffargis',
      divers: undefined,
      plastiques: undefined,
      verre: undefined,
      vegetaux: undefined
    });
    if (globalService.debug) console.log('Initialization 1 : Zoning en localStorage :',{zoningInLocalStorage});
    reducerDispatch({type:REDUCER_ACTIONS.SET_USERZONING,payload:{userZoning:zoningInLocalStorage}});

    // fetch les datas json
    const citiesAsArray = process.env.REACT_APP_CITIES_ID?.split(',')
    if (globalService.debug) console.log('Initialization 2 : Villes à charger :',{citiesAsArray});
    if (citiesAsArray) {
      citiesAsArray.forEach(async cityID => {
        const data = await fetchCityData(cityID)
        if (data) {
          // on merge les annees dans l'objet racine
          const years = Object.keys(data.datas)
          let documents:any = []
          let divers:any = {}
          let plastiques:any = {}
          let verre:any = {}
          let vegetaux:any = {}
          years.forEach(year => {
            // on remplace les macros
            data.datas[year].documents.forEach((doc:any) => {
              doc.path = doc.path.replaceAll('{basePath}',data.basePath)
              doc.path = doc.path.replaceAll('{YEAR}',year)
              doc.name = doc.name.replaceAll('{basePath}',data.basePath)
              doc.name = doc.name.replaceAll('{YEAR}',year)
              doc.download = doc.download.replaceAll('{basePath}',data.basePath)
              doc.download = doc.download.replaceAll('{YEAR}',year)
            })
            if (parseInt(year) < (new Date()).getFullYear() && (new Date()).getMonth() < 4) {
              documents = documents.concat(data.datas[year].documents)
              divers = {...data.datas[year].collectTypes.divers}
              plastiques = {...data.datas[year].collectTypes.plastiques}
              verre = {...data.datas[year].collectTypes.verre}
              vegetaux = {...data.datas[year].collectTypes.vegetaux}
            }
            if (parseInt(year) === (new Date()).getFullYear()) {
              documents = documents.concat(data.datas[year].documents)
              divers = {...divers,...data.datas[year].collectTypes.divers}
              plastiques = {...data.datas[year].collectTypes.plastiques}
              verre = {...data.datas[year].collectTypes.verre}
              vegetaux = {...data.datas[year].collectTypes.vegetaux}
            }
            if (parseInt(year) > (new Date()).getFullYear()) {
              documents = documents.concat(data.datas[year].documents)
              // !! on ne prend que les dates, pas les infos
              const diversOnlyDays = {...data.datas[year].collectTypes.divers}
              const plastiquesOnlyDays = {...data.datas[year].collectTypes.plastiques}
              const verreOnlyDays = {...data.datas[year].collectTypes.verre}
              const vegetauxOnlyDays = {...data.datas[year].collectTypes.vegetaux}
              Object.keys(diversOnlyDays).forEach(zoneKey => {
                delete diversOnlyDays[zoneKey].labelZone
                delete diversOnlyDays[zoneKey].frequency
                delete diversOnlyDays[zoneKey].notes
              })
              Object.keys(plastiquesOnlyDays).forEach(zoneKey => {
                delete plastiquesOnlyDays[zoneKey].labelZone
                delete plastiquesOnlyDays[zoneKey].frequency
                delete plastiquesOnlyDays[zoneKey].notes
              })
              Object.keys(verreOnlyDays).forEach(zoneKey => {
                delete verreOnlyDays[zoneKey].labelZone
                delete verreOnlyDays[zoneKey].frequency
                delete verreOnlyDays[zoneKey].notes
              })
              Object.keys(vegetauxOnlyDays).forEach(zoneKey => {
                delete vegetauxOnlyDays[zoneKey].labelZone
                delete vegetauxOnlyDays[zoneKey].frequency
                delete vegetauxOnlyDays[zoneKey].notes
              })
              divers = {...divers,...diversOnlyDays}
              plastiques = {...plastiques,...plastiquesOnlyDays}
              verre = {...verre,...verreOnlyDays}
              vegetaux = {...vegetaux,...vegetauxOnlyDays}
            }
          })
          data.documents = documents
          data.collectTypes = {}
          data.collectTypes.divers = divers
          data.collectTypes.plastiques = plastiques
          data.collectTypes.verre = verre
          data.collectTypes.vegetaux = vegetaux
          if (globalService.debug) console.log('Initialization 3a : Chargement ville :',{cityID,data});
          reducerDispatch({type:REDUCER_ACTIONS.SET_ALLDATAS,payload:{cityID,data}});

          // on calcule les données lié au user
          const userDatas = extractUserDatas(allDatas, zoningInLocalStorage);
          if (GlobalService.getInstance().debug) console.log('Initialization 3b : Chargement userDatas :',{cityID,userDatas});
          reducerDispatch({type:REDUCER_ACTIONS.SET_USERDATAS,payload:{userDatas}});

          // on calcule les valeurs deduites
          const deductedUserDatas = extractDeductedUserDatas(userDatas);
          if (GlobalService.getInstance().debug) console.log('Initialization 3c : Chargement valeurs déduites :',{cityID,deductedUserDatas});
          reducerDispatch({type:REDUCER_ACTIONS.SET_DEDUCTEDUSERDATAS,payload:{deductedUserDatas}});
        }
      })
    }

    // Lit l'info read
    const infoReadInLocalStorage = getInfoReadFromLocalStorage(undefined);
    if (globalService.debug) console.log('Initialization 4 : Info lue en localStorage',{infoReadInLocalStorage});
    reducerDispatch({type:REDUCER_ACTIONS.SET_INFOREAD,payload:{infoRead:infoReadInLocalStorage}});

    // on fetch le lastInfo
    fetchNewsData().then((newsData) => {
      if (newsData && newsData.news && newsData.news.length > 0) {
        if (globalService.debug) console.log('Initialization 5 : News :',{news: newsData.news});
        reducerDispatch({type:REDUCER_ACTIONS.SET_LAST_INFO,payload:{lastInfo:newsData.news[0]}});
      }
    })

    reducerDispatch({type:REDUCER_ACTIONS.SET_LOADING_STATE,payload:{status:'loaded'}});
  },[]);


  // on update les infos users si les datas changent
  useEffect(() => {
    if (GlobalService.getInstance().debug) console.log('Update : ',{allDatas, userZoning});

    // on calcule les données lié au user
    const userDatas = extractUserDatas(allDatas, userZoning);
    if (GlobalService.getInstance().debug) console.log('Update : ',{userDatas});
    reducerDispatch({type:REDUCER_ACTIONS.SET_USERDATAS,payload:{userDatas}});

    // on calcule les valeurs deduites
    const deductedUserDatas = extractDeductedUserDatas(userDatas);
    if (GlobalService.getInstance().debug) console.log('Update : ',{deductedUserDatas});
    reducerDispatch({type:REDUCER_ACTIONS.SET_DEDUCTEDUSERDATAS,payload:{deductedUserDatas}});
  },[allDatas, userZoning])

  return (
    <Routing />
  );
}

export default Initializer;
