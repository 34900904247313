import Component from './NextBlocks';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';

// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext                from '../../datas/contexts/GlobalContext';

const ComponentInMemo = memo(Component);

const ComponentInContext = ({nextBlocksClassAnim}:{nextBlocksClassAnim:string}) => {

  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {userDatas,deductedUserDatas} = state;

  return <ComponentInMemo
            userDatas={userDatas}
            deductedUserDatas={deductedUserDatas}
            nextBlocksClassAnim={nextBlocksClassAnim} />;
};

export default memo(ComponentInContext);
