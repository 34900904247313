import React, {useReducer} from 'react';

import './App.scss';

// --- GlobalContext ----
// On utilise le Context API pour faire descendre les datas emarquées
// sur tout l'arbre de composant
import GlobalContext from './datas/contexts/GlobalContext';
import reducer from './datas/contexts/reducers/reducer';
import DEFAULT_STATE from './datas/contexts/state/defaults';

import Initializer from './Initializer';

function App() {
  const [reducerState,reducerDispatch] = useReducer(reducer,DEFAULT_STATE);

  return (
    <div className="App background">
      <GlobalContext.Provider value={{state:reducerState,dispatch:reducerDispatch}}>
        <Initializer />
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
