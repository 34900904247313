import React from 'react';

// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext                from '../../datas/contexts/GlobalContext';

import {T_CITIES_ZONES} from '../../services/I_ZONINGS_MAP';
import {T_ZONING,T_CITIES_ALL_DATAS} from '../../datas/contexts/state/interfaces';

// --- Services ----
import GlobalService from '../../services/GlobalService';
import { extractDeductedUserDatas, extractUserDatas, updateUserZoning } from '../../datas/helpers';

// import './modalInfo.scss';

import SelectCity  from "../../components/selectCity";
import SelectCollectTypeZone  from "../../components/selectCollectTypeZone";
import REDUCER_ACTIONS from '../../datas/contexts/reducers/actions';
import { setZoningInLocalStorage } from '../../datas/helpers/localStorage';

interface I_PROPS {
  zoningsMap?: T_CITIES_ZONES;
  allDatas?: T_CITIES_ALL_DATAS;
  userZoning?: T_ZONING;
  onClickClose: Function;
}

function ModalZoning({zoningsMap,allDatas,userZoning,onClickClose} : I_PROPS) {

  const {dispatch} = useContext(GlobalContext);

  // services
  const globalService = GlobalService.getInstance();

  let allDiversZones = userZoning  && userZoning.city && zoningsMap && zoningsMap[userZoning.city] && zoningsMap[userZoning.city].divers ? zoningsMap[userZoning.city].divers : undefined;
  let allPlastiquesZones = userZoning  && userZoning.city && zoningsMap && zoningsMap[userZoning.city] && zoningsMap[userZoning.city].plastiques ? zoningsMap[userZoning.city].plastiques : undefined;
  let allVerreZones = userZoning  && userZoning.city && zoningsMap && zoningsMap[userZoning.city] && zoningsMap[userZoning.city].verre ? zoningsMap[userZoning.city].verre : undefined;
  let allVegetauxZones = userZoning  && userZoning.city && zoningsMap && zoningsMap[userZoning.city] && zoningsMap[userZoning.city].vegetaux ? zoningsMap[userZoning.city].vegetaux : undefined;

  const selectCity = (selectedCityID:string) => {
    if(globalService.debug) console.log('Select city',selectedCityID);

    // recalculer userZoning
    const newUserZoning = updateUserZoning(selectedCityID,zoningsMap);
    // changer userZoning dans le state
    dispatch({type:REDUCER_ACTIONS.SET_USERZONING,payload:{userZoning:newUserZoning}});
    // l'enregistrer en localStorage
    setZoningInLocalStorage(newUserZoning);
    // recalc userData et deductedUserDatas, les mettre en state
    const newUserDatas = extractUserDatas(allDatas, newUserZoning);
    dispatch({type:REDUCER_ACTIONS.SET_USERDATAS,payload:{userDatas:newUserDatas}});
    const newDeductedUserDatas = extractDeductedUserDatas(newUserDatas);
    dispatch({type:REDUCER_ACTIONS.SET_DEDUCTEDUSERDATAS,payload:{deductedUserDatas:newDeductedUserDatas}});
  }

  const selectCollectTypeZone = (collectType: string, selectedZone:string) => {
    if(globalService.debug) console.log('Select',selectedZone,'for',collectType);

    // recalculer userZoning
    let newUserZoning = {...userZoning};
    (newUserZoning as any)[collectType] = selectedZone;

    // changer userZoning dans le state
    dispatch({type:REDUCER_ACTIONS.SET_USERZONING,payload:{userZoning:newUserZoning}});
    // l'enregistrer en localStorage
    setZoningInLocalStorage(newUserZoning);
    // recalc userData et deductedUserDatas, les mettre en state
    const newUserDatas = extractUserDatas(allDatas, newUserZoning);
    dispatch({type:REDUCER_ACTIONS.SET_USERDATAS,payload:{userDatas:newUserDatas}});
    const newDeductedUserDatas = extractDeductedUserDatas(newUserDatas);
    dispatch({type:REDUCER_ACTIONS.SET_DEDUCTEDUSERDATAS,payload:{deductedUserDatas:newDeductedUserDatas}});
  };

  return (
    <div className="modal">
      <div className="modal-overlay" onClick={() => onClickClose()}></div>
      <div className="modal-card">
        <h2>Ma ville, mes zones</h2>
        <div className="explain"><i>Se reporter &agrave; la carte</i></div>
        {zoningsMap ? (
        <div className="fields">
          <SelectCity onSelectCity={selectCity}/>
          <div>-</div>
          <div className="zones">
            <SelectCollectTypeZone
              name={'Divers'}
              zones={allDiversZones}
              userZoneSelected={userZoning?.divers}
              onZoneSelect={(zone:string) => selectCollectTypeZone('divers',zone)}
              suffix={'divers'} />
            <SelectCollectTypeZone
              name={'Plastiques / Papiers'}
              zones={allPlastiquesZones}
              userZoneSelected={userZoning?.plastiques}
              onZoneSelect={(zone:string) => selectCollectTypeZone('plastiques',zone)}
              suffix={'plastiques'} />
            <SelectCollectTypeZone
              name={'Verre'}
              zones={allVerreZones}
              userZoneSelected={userZoning?.verre}
              onZoneSelect={(zone:string) => selectCollectTypeZone('verre',zone)}
              suffix={'verre'} />
            <SelectCollectTypeZone
              name={'Végétaux'}
              zones={allVegetauxZones}
              userZoneSelected={userZoning?.vegetaux}
              onZoneSelect={(zone:string) => selectCollectTypeZone('vegetaux',zone)}
              suffix={'vegetaux'} />
          </div>
        </div>
        ) : null}
        <div className="buttons">
          <div className="closeModalButton" onClick={() => onClickClose()}>OK</div>
        </div>
      </div>
    </div>
  );
}
export default ModalZoning;
