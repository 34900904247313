import I_Component from './I_NewsBox';
import Component from './NewsBox';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';

// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext from '../../datas/contexts/GlobalContext';

const ComponentInMemo = memo(Component);

const NewsBoxInContext = () => {
  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {lastInfo} = state;

  return <ComponentInMemo lastInfo={lastInfo} />;
};

export default memo(NewsBoxInContext);
export {I_Component};
