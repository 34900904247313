import I_STATE from './interfaces';

const DEFAULT_STATE:I_STATE = {
  loadingStatus: undefined,
  allDatas: {},
  lastInfo: undefined,
  infoRead: undefined,
  userDatas: {
    id: undefined,
    labelCity: undefined,
    warningCity: undefined,
    documents: undefined,
    divers: undefined,
    plastiques: undefined,
    verre: undefined,
    vegetaux: undefined,
  },
  deductedUserDatas: {
    divers: undefined,
    plastiques: undefined,
    verre: undefined,
    vegetaux: undefined,
  },
  userZoning: {
    city: undefined,
    divers: undefined,
    plastiques: undefined,
    verre: undefined,
    vegetaux: undefined
  },
  userInfoRead: undefined
};
export default DEFAULT_STATE;
