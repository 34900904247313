import React,{useMemo} from 'react';

import {getFormattedToday} from '../../utils/dateHelper';
import './Now.scss';

function Now() {

  // On calcule la date du jour qu'une seule fois au chargement,
  // pas besoin de recalculer à chaque changement de state
  const today = useMemo(
    () => getFormattedToday(),
    []
  );

  return (
    <div className="today">Nous sommes<br/>{ today }</div>
  );
}
export default Now;
