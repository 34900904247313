// --- Services ----
import GlobalService from '../../services/GlobalService';

/**
 * API
 * @folder APIs
 * @class
 */
class Api {

  FETCH_TIMEOUT = 16000;  // timeout forcé d'un fetch, en milliseconds

  // constructor() {

  // }

  /**
   * @returns {Promise}
   * contenant un json {  }
   */
  getLastInfo() {
    if (GlobalService.getInstance().debug) console.log("Api - getLastInfo",{env:process.env.REACT_APP_ENV,api_mode:process.env.REACT_APP_API_MODE});

    const params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const URL_LASTINFO = process.env.REACT_APP_ENV !== 'production' ? 'https://poubelles.asnidren.com/assets/json/lastInfo.php?d='+(new Date()).getTime() : 'https://poubelles.asnidren.com/assets/json/lastInfo.json?d='+(new Date()).getTime();

    return this._customFetch(URL_LASTINFO,params);
  }




  /**
   * Fetch custom, enable to stop the request after a timeout
   * @param {string} url
   * @param {object} options
   * @param {number} timeout
   * @returns {Promise}
   */
  async _customFetch(url:string, options:any, timeout:number = this.FETCH_TIMEOUT) {
    const controller = new AbortController();
    const id = setTimeout(() => {
      controller.abort();
      if (GlobalService.getInstance().debug) console.log("Api - _customFetch - Request timed out ("+timeout+")", url);
    }, timeout);
    const response = await fetch(url, {
      ...options,
      signal: controller.signal
    })
    clearTimeout(id);
    if (GlobalService.getInstance().debug) console.log("API - _customFetch - Request", url, "returned", response);
    if (!response.ok) {
      if (GlobalService.getInstance().debug) console.log(`API - _customFetch - HTTP error! status: ${response.status}`);
    }
    return response;
  }

}
export default Api;
