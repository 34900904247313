import {T_LAST_INFO} from '../datas/contexts/state/interfaces';
import Api from '../datas/fetchs/api';
import {isDebugEnabled} from '../utils/appHelper';

/**
 * Service
 * @folder Services
 * @class
 */
class GlobalService {
  private static _instance: GlobalService;

  debug: boolean;  // true si debug activé
  api: any;

  /**
   * Renvoie l'instance du service
   * @returns {GlobalService} singleton instance
   */
  static getInstance() {
    if (!GlobalService._instance) {
      GlobalService._instance = new GlobalService();
      if (GlobalService._instance.debug) console.log('GlobalService - instanciated with',{debug:GlobalService._instance.debug});
    }
    return GlobalService._instance;
  }

  private constructor() {
    console.log('Welcome on Poubelles! Env vars are :',{env:process.env.REACT_APP_ENV,debug:process.env.REACT_APP_DEBUG_MODE});
    this.debug = isDebugEnabled();
    this.api = new Api();
    if (this.debug) console.log('Debug is enabled.');
  }

  /**
   * Promise renvoyant une erreur si pour quelque raison que ce soit, on a pas le last info
   * @returns {Promise}
   */
  async getLastInfo():Promise<T_LAST_INFO|undefined> {
    try {
      const response = await this.api.getLastInfo();
      if (this.debug) console.log('GlobalService - getLastInfo - returned',response);

      const responseOK = response.ok;
      const responseStatus = response.status;
      const responseStatusText = response.statusText;

      // ---------
      // traitement de la reponse

      if (!responseOK || responseStatus !== 200) {
        return Promise.reject({
          error: true,
          errorCode: responseStatus,
          errorText: responseStatusText,
          errorMessage: 'response invalid, response status '+responseStatus
        });
      }

      const responseBody:T_LAST_INFO = await response.json();
      if (this.debug) console.log('GlobalService - getLastInfo - returned body :',{responseBody});

      return responseBody;

    }
    catch (error) {
      console.error('GlobalService - getLastInfo - An error occured',error);
      return Promise.reject({
        error: true,
        errorCode: undefined,
        errorText: undefined,
        errorMessage: 'An error occured : '+error
      });
    }
  }





}
export default GlobalService;
