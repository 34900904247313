import ModalInfo from './ModalInfo';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';

// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext from '../../datas/contexts/GlobalContext';

const MemoModalInfo = memo(ModalInfo);

const ModalInfoInContext = ({onClickClose}:{onClickClose:Function}) => {
  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {lastInfo} = state;

  return <MemoModalInfo lastInfo={lastInfo} onClickClose={onClickClose}/>;
};

export default memo(ModalInfoInContext);
