import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import Home      from "./pages/home";
import Calendar     from "./pages/calendar";
import Documents from "./pages/documents";
import About     from "./pages/about";

const Routing = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/documents",
      element: <Documents />,
    },
    {
      path: "/calendrier",
      element: <Calendar />,
    },
    {
      path: "/assets",
      element: <Documents />,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />
    },
  ], { basename: "/" });

  return (
    <RouterProvider router={router} />
  );
}

export default Routing;
