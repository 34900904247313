
export const fetchCityData = async (cityID:string) => {
  const url = `${process.env.REACT_APP_JSON_BASEPATH}/ville-${cityID}.json?d=${(new Date()).getTime()}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const data = await response.json();
  console.log({url,response,data})
  return data
}
