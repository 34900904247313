/**
 * Renvoie la date du jour calée sur 00h00mm00
 * @returns
 */
const getCurrentDate = ():Date => {
  var currentDate = new Date();
  var currentDateInStr = currentDate.getFullYear() + '-' + ((currentDate.getMonth()+1) < 10 ? '0' : '') + (currentDate.getMonth()+1) + '-' + (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate() + 'T00:00:00Z';
  currentDate = new Date(currentDateInStr);
  return currentDate;
};

/**
 * Renvoie la date calée sur 00hh00mm00 correspondant à la valeur passée
 * @param dateInStr Ex: 2023-06-23
 * @returns
 */
const getNextDate = (dateInString: string):Date => {
  const date = new Date(dateInString + 'T00:00:00Z');
  return date;
};

/**
 * Formatte une date en "Lundi 02 Juin 2023"
 * @param nextDate
 * @returns
 */
const formatDate = (nextDate: Date):string => {
  var day = weekdays[nextDate.getDay()] + ' ';
  var dayNumber = (nextDate.getDate() < 10 ? '0' : '') + nextDate.getDate() + ' ';
  // var month = ((nextDate.getMonth()+1) < 10 ? '0' : '') + (nextDate.getMonth()+1) ;
  var month = months[nextDate.getMonth()] + ' ';
  var year = nextDate.getFullYear();
  return day + dayNumber + month + year;
};

/**
 * Renvoie la date courante formattée en "Lundi 02 Juin 2023"
 * @returns
 */
const getFormattedToday = ():string => {
  var currentDate = getCurrentDate();
  return formatDate(currentDate);
}

export {getCurrentDate,getNextDate,formatDate,getFormattedToday};





const weekdays = new Array(7);
weekdays[0] = "Dimanche";
weekdays[1] = "Lundi";
weekdays[2] = "Mardi";
weekdays[3] = "Mercredi";
weekdays[4] = "Jeudi";
weekdays[5] = "Vendredi";
weekdays[6] = "Samedi";

const months = new Array(12);
months[0] = "Jan";
months[1] = "Fév";
months[2] = "Mars";
months[3] = "Avr";
months[4] = "Mai";
months[5] = "Juin";
months[6] = "Juil";
months[7] = "Août";
months[8] = "Sept";
months[9] = "Oct";
months[10] = "Nov";
months[11] = "Déc";
