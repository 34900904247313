/**
 * Récupère un paramètre d'url
 * @param name
 * @returns
 */
const readUrlParam = (name:string) => {
  try{
    var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null)
      return null;
    return decodeURI(results[1]) || 'false';
  }catch(err){
    console.error(`Cannot read URL param ${name}`,err);
    return null;
  }
};

/**
 * Selon le paramètre d'environnement REACT_APP_DEBUG_MODE
 * * urlparam : depend de ?debug=true
 * * true : true
 * * non spécifié : false
 * @returns
 */
const isDebugEnabled = ():boolean => {
  const env = process.env.REACT_APP_ENV;
  if (env === 'production') {
    const envDebug = process.env.REACT_APP_DEBUG_MODE;
    if (envDebug && envDebug.toLowerCase() === 'urlparam') {
      const debugParam = readUrlParam('debug');
      const DEBUG = !!debugParam && (debugParam.toLowerCase() === 'true');
      return DEBUG;
    }
    else if (envDebug === 'true') {
      return true;
    }
    return false;
  }
  else {
    return true;
  }
};

export {readUrlParam,isDebugEnabled};
