import React from 'react';

import {T_USER_DATAS,T_DEDUCTED_USER_DATAS} from '../../datas/contexts/state/interfaces';

import NextBlock  from "../nextBlock";
import './NextBlock.scss';

interface I_PROPS {
  userDatas?: T_USER_DATAS;
  deductedUserDatas?: T_DEDUCTED_USER_DATAS;
  nextBlocksClassAnim?: string; // classe gerant l'animation css
}

function NextBlocks({userDatas,deductedUserDatas,nextBlocksClassAnim} : I_PROPS) {

  return (
    <div className="nextin columns">

      {userDatas && userDatas.divers ?(
      <NextBlock
        classAnim={nextBlocksClassAnim}
        label={'Divers'}
        value={userDatas.divers.frequency}
        next={deductedUserDatas && deductedUserDatas.divers ? deductedUserDatas.divers.next : undefined}
        date={deductedUserDatas && deductedUserDatas.divers ? deductedUserDatas.divers.date : undefined}
        more={userDatas.divers.notes}
        alert={deductedUserDatas && deductedUserDatas.divers ? deductedUserDatas.divers.alert : undefined}
        svg={'bin'}
        isEmpty={!deductedUserDatas || !deductedUserDatas.divers || !deductedUserDatas.divers.hasnext}
        optClass={'divers'}
        />
      ) : null}

      {userDatas && userDatas.plastiques ?(
      <NextBlock
        classAnim={nextBlocksClassAnim}
        label={'Plastiques / Papiers'}
        value={userDatas.plastiques.frequency}
        next={deductedUserDatas && deductedUserDatas.plastiques ? deductedUserDatas.plastiques.next : undefined}
        date={deductedUserDatas && deductedUserDatas.plastiques ? deductedUserDatas.plastiques.date : undefined}
        more={userDatas.plastiques.notes}
        alert={deductedUserDatas && deductedUserDatas.plastiques ? deductedUserDatas.plastiques.alert : undefined}
        svg={'garbage'+(deductedUserDatas && deductedUserDatas.plastiques && deductedUserDatas.plastiques.alert==='imminent'?'-red':'')}
        isEmpty={!deductedUserDatas || !deductedUserDatas.plastiques || !deductedUserDatas.plastiques.hasnext}
        optClass='plastique'
        />
      ) : null}

      {userDatas && userDatas.verre ?(
      <NextBlock
        classAnim={nextBlocksClassAnim}
        label={'Verre'}
        value={userDatas.verre.frequency}
        next={deductedUserDatas && deductedUserDatas.verre ? deductedUserDatas.verre.next : undefined}
        date={deductedUserDatas && deductedUserDatas.verre ? deductedUserDatas.verre.date : undefined}
        more={userDatas.verre.notes}
        alert={deductedUserDatas && deductedUserDatas.verre ? deductedUserDatas.verre.alert : undefined}
        svg={'glass-container'+(deductedUserDatas && deductedUserDatas.verre && deductedUserDatas.verre.alert==='imminent'?'-red':'')}
        isEmpty={!deductedUserDatas || !deductedUserDatas.verre || !deductedUserDatas.verre.hasnext}
        optClass={'verre'}
        />
      ) : null}

      {userDatas && userDatas.vegetaux ?(
      <NextBlock
        classAnim={nextBlocksClassAnim}
        label={'Végétaux'}
        value={userDatas.vegetaux.frequency}
        next={deductedUserDatas && deductedUserDatas.vegetaux ? deductedUserDatas.vegetaux.next : undefined}
        date={deductedUserDatas && deductedUserDatas.vegetaux ? deductedUserDatas.vegetaux.date : undefined}
        more={userDatas.vegetaux.notes}
        alert={deductedUserDatas && deductedUserDatas.vegetaux ? deductedUserDatas.vegetaux.alert : undefined}
        svg={'recycle-bin'+(deductedUserDatas && deductedUserDatas.vegetaux && deductedUserDatas.vegetaux.alert==='imminent'?'-red':'')}
        isEmpty={!deductedUserDatas || !deductedUserDatas.vegetaux || !deductedUserDatas.vegetaux.hasnext}
        optClass={'vegetaux'}
        />
      ) : null}

    </div>
  );
}
export default NextBlocks;
