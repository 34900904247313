import React from 'react';
import useMetas from '../../datas/hooks/useMetas';

import { Calendar } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import Menu from "../../components/menu";
import Version  from "../../components/version";

import './calendar.scss';

interface I_PROPS {
  plastiques?: string | string[];
  verre?: string | string[];
  vegetaux?: string | string[];
}

function MyCalendar(props: I_PROPS) {

  // hook metas
  useMetas('Calendriers de collecte','Les prochaines dates de collecte des déchets au Perray-en-Yvelines et aux Essarts-le-roi (78)');

  // valeurs récupérées du reducer state, placées en context, transmises via props
  const {plastiques,verre,vegetaux} = props;

  const renderCell = (date:Date) => {
    if (!plastiques && !verre && !vegetaux)
      return null;

    const cellDate = date.toISOString().split('T')[0];
    return (
      <div>
        {plastiques && plastiques !== 'auto' && plastiques.indexOf(cellDate) !== -1 ? (
          <div className="bullet plastiques">
            <img src={'../assets/images/garbage.svg'} alt="" />
            <span>Plastiques / Papier</span>
          </div>
        ) : null}
        {verre && verre !== 'auto' && verre.indexOf(cellDate) !== -1 ? (
          <div className="bullet verre">
            <img src={'../assets/images/glass-container.svg'} alt="" />
            <span>Verre</span>
          </div>
        ) : null}
        {vegetaux && vegetaux !== 'auto' && vegetaux.indexOf(cellDate) !== -1 ? (
          <div className="bullet vegetaux">
            <img src={'../assets/images/recycle-bin.svg'} alt="" />
            <span>Végétaux</span>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="calendrier">

      <Menu />

      <div className="calendrier">
        <div className="titles">
          <h1>Calendrier</h1>
        </div>
        <div className="calendar">
          <Calendar
            renderCell={renderCell}/>
        </div>
      </div>

      <Version />

    </div>
  );
}
export default MyCalendar;
