import { T_CITIES_NAMES, T_CITIES_ZONES, T_CITY_ZONE, T_CITY_ZONES } from "../services/I_ZONINGS_MAP";
import { formatDate, getCurrentDate, getNextDate } from "../utils/dateHelper";
import { getImminenceClassName, getWaitingForNextLabel } from "../utils/logicHelper";
import { T_CITIES_ALL_DATAS, T_CITY, T_COLLECT_TYPE, T_COLLECT_ZONE, T_DEDUCTED_DATA_TYPE, T_DEDUCTED_USER_DATAS, T_USER_DATAS, T_ZONING } from "./contexts/state/interfaces";

/**
 * Extrait de toutes les donnees les donnees correspondant aux parametres de l'utilisateur
 * @param allDatas
 * @param userZoning
 * @returns
 */
export const extractUserDatas = (allDatas:T_CITIES_ALL_DATAS|undefined,userZoning:T_ZONING):T_USER_DATAS|undefined => {
  console.log('extractUserDatas..',{allDatas,userZoning});

  // si on a deja pas de valeurs, renvoie vide
  if (!allDatas || Object.keys(allDatas).length === 0) {
    console.log('extractUserDatas - Cannot get user datas, no embedded datas.');
    return {};
  }

  // verifie la ville
  const cities = Object.keys(allDatas);
  if (!userZoning || !userZoning.city || cities.indexOf(userZoning.city) === -1) {
    console.log('extractUserDatas - Cannot get user datas with empty or invalid userZoning.');
    return {};
  }

  const cityID = userZoning.city;
  const userDiversZone = userZoning.divers;
  const userPlastiquesZone = userZoning.plastiques;
  const userVerreZone = userZoning.verre;
  const userVegetauxZone = userZoning.vegetaux;

  // extraie les datas de la ville
  let userCityAllDatas:T_CITY = allDatas[cityID];
  console.log('extractUserDatas',{userCityAllDatas});

  // extraie les datas par type de collecte
  let collectTypes = userCityAllDatas.collectTypes;
  const cityDiversZones = collectTypes?.divers ? Object.keys(collectTypes.divers) : undefined;
  const cityPlastiquesZones = collectTypes?.plastiques ? Object.keys(collectTypes.plastiques) : undefined;
  const cityVerreZones = collectTypes?.verre ? Object.keys(collectTypes.verre) : undefined;
  const cityVegetauxZones = collectTypes?.vegetaux ? Object.keys(collectTypes.vegetaux) : undefined;

  // extraie les datas par type de collecte
  let userCollectDivers = _getCollectDatas(userDiversZone, cityDiversZones, collectTypes?.divers);
  let userCollectPlastiques = _getCollectDatas(userPlastiquesZone, cityPlastiquesZones, collectTypes?.plastiques);
  let userCollectVerre = _getCollectDatas(userVerreZone, cityVerreZones, collectTypes?.verre);
  let userCollectVegetaux = _getCollectDatas(userVegetauxZone, cityVegetauxZones, collectTypes?.vegetaux);

  const userDatasToDisplay = {
    id: userCityAllDatas.id,
    labelCity: userCityAllDatas.labelCity,
    warningCity: userCityAllDatas.warningCity,
    lastUpdate: userCityAllDatas.lastUpdate,
    documents: userCityAllDatas.documents,
    divers: userCollectDivers,
    plastiques: userCollectPlastiques,
    verre: userCollectVerre,
    vegetaux: userCollectVegetaux,
  };
  console.log('extractUserDatas - ',{allDatas,userZoning,userDatasToDisplay});
  return userDatasToDisplay;
}


const _getCollectDatas = (userCollectZone:string|undefined,cityCollectZones:string[]|undefined,collectType:T_COLLECT_TYPE):T_COLLECT_ZONE|undefined => {
  // si y'a pas de donnees
  if (!cityCollectZones || cityCollectZones.length < 1)
    return undefined;

  // si il n'a pas selectionne de zone, ou une zone invalide
  if (userCollectZone === undefined || (cityCollectZones && cityCollectZones.length > 0 && cityCollectZones.indexOf(userCollectZone) === -1)) {
    // cas où la ville n'a qu'une zone pour cette collecte => on lui affiche direct les données de la zone
    if (cityCollectZones.length === 1)
      return collectType[cityCollectZones[0]];
    // cas où la ville a plusieurs zones => on le force à choisir, on n'affiche rien
    return undefined;
  }

  // si il a une selection correcte, on extraie les donnees
  return collectType[userCollectZone];
}


/**
 *
 * @param userDatas
 * @returns
 */
export const extractDeductedUserDatas = (userDatas:T_USER_DATAS|undefined):T_DEDUCTED_USER_DATAS => {

  const currentDate = getCurrentDate();

  const divers = _deducteUserData(userDatas?.divers,currentDate);
  const plastiques = _deducteUserData(userDatas?.plastiques,currentDate);
  const verre = _deducteUserData(userDatas?.verre,currentDate);
  const vegetaux = _deducteUserData(userDatas?.vegetaux,currentDate);

  const deductedDatas = {
    divers: divers,
    plastiques: plastiques,
    verre: verre,
    vegetaux: vegetaux
  };
  return deductedDatas;
}


/**
 *
 * @param collectZone
 * @param currentDate
 * @returns
 */
const _deducteUserData = (collectZone:T_COLLECT_ZONE|undefined,currentDate:Date):T_DEDUCTED_DATA_TYPE => {
  let result:T_DEDUCTED_DATA_TYPE = {
    hasnext: undefined,
    date: undefined,
    alert: undefined,
    next: undefined
  };
  if (collectZone && collectZone.days){
    if (collectZone.days==='auto'){
      result.hasnext = true;
    }
    else {
      for(var i = 0; i < collectZone.days.length; i++){
        var nextDate = getNextDate(collectZone.days[i]);
        if (nextDate.getTime() >= currentDate.getTime()){
          result.date = formatDate(nextDate);
          result.alert = getImminenceClassName(currentDate, nextDate);
          result.next = getWaitingForNextLabel(currentDate, nextDate);
          result.hasnext = true;
          break;
        }
      }
    }
  }
  return result;
}


/**
 * Extrait des données la map des options de zoning
 * @param allDatas
 * @returns
 */
export const extractZoningsMap = (allDatas:T_CITIES_ALL_DATAS|undefined):T_CITIES_ZONES => {

  // si on a deja pas de valeurs, renvoie vide
  if (!allDatas || Object.keys(allDatas).length === 0) {
    console.log('extractZoningMap - Cannot get zonings map, no embedded datas.');
    return {};
  }
  // console.log('extractZoningMap - ',{allDatas});

  // liste des villes
  const citiesKeys = Object.keys(allDatas);

  let zoningsMap:T_CITIES_ZONES = {};
  citiesKeys.forEach((cityKey) => {
    const cityDatas = allDatas[cityKey];
    // console.log('extractZoningMap - ',cityKey);

    // console.log('extractZoningMap - divers...');
    let cityDiversZones:T_CITY_ZONE = _extractTypeZoningsMap(cityDatas?.collectTypes?.divers);
    // console.log('extractZoningMap - plastiques...');
    let cityPlastiquesZones:T_CITY_ZONE = _extractTypeZoningsMap(cityDatas?.collectTypes?.plastiques);
    // console.log('extractZoningMap - verre...');
    let cityVerreZones:T_CITY_ZONE = _extractTypeZoningsMap(cityDatas?.collectTypes?.verre);
    // console.log('extractZoningMap - vegetaux...');
    let cityVegetauxZones:T_CITY_ZONE = _extractTypeZoningsMap(cityDatas?.collectTypes?.vegetaux);


    const cityZoning:T_CITY_ZONES = {
      labelCity: allDatas[cityKey].labelCity,
      divers: cityDiversZones,
      plastiques: cityPlastiquesZones,
      verre: cityVerreZones,
      vegetaux: cityVegetauxZones
    }
    zoningsMap[cityKey] = cityZoning;
  });

  return zoningsMap;
}


/**
 *
 * @param collectTypeDatas
 * @returns
 */
const _extractTypeZoningsMap = (collectTypeDatas:T_COLLECT_TYPE|undefined):T_CITY_ZONE => {

  // si on a deja pas de valeurs, renvoie vide
  if (!collectTypeDatas || Object.keys(collectTypeDatas).length === 0) {
    // console.log('_extractTypeZoningsMap - Cannot get zonings of type, no embedded datas.');
    return {};
  }

  const zoneKeys:string[] = Object.keys(collectTypeDatas);

  // si aucune zone pour ce type de collecte
  if (!zoneKeys || zoneKeys.length < 1)
    return {};

  let result:T_CITY_ZONE = {};
  zoneKeys.forEach(zoneKey => {
    result[zoneKey] = collectTypeDatas[zoneKey].labelZone;
  });

  return result;
}


/**
 *
 * @param cityKey
 * @param zoningsMap
 * @returns
 */
export const updateUserZoning = (cityKey:string,zoningsMap:T_CITIES_ZONES|undefined):T_ZONING => {

  // si on a deja pas de valeurs, vide
  if (!zoningsMap || Object.keys(zoningsMap).length === 0)
    return {};

  // si ville selectionnee est invalide
  if ((Object.keys(zoningsMap)).indexOf(cityKey) === -1)
    return {};

  const cityZonings = zoningsMap[cityKey];

  let diversDefaultZone = undefined;
  const diversZones = cityZonings.divers;
  if (diversZones && Object.keys(diversZones).length > 1) {
    diversDefaultZone = Object.keys(diversZones)[0];
  }
  let plastiquesDefaultZone = undefined;
  const plastiquesZones = cityZonings.plastiques;
  if (plastiquesZones && Object.keys(plastiquesZones).length > 1) {
    plastiquesDefaultZone = Object.keys(plastiquesZones)[0];
  }
  let verreDefaultZone = undefined;
  const verreZones = cityZonings.verre;
  if (verreZones && Object.keys(verreZones).length > 1) {
    verreDefaultZone = Object.keys(verreZones)[0];
  }
  let vegetauxDefaultZone = undefined;
  const vegetauxZones = cityZonings.vegetaux;
  if (vegetauxZones && Object.keys(vegetauxZones).length > 1) {
    vegetauxDefaultZone = Object.keys(vegetauxZones)[0];
  }

  let newUserZoning = {
    city: cityKey,
    divers: diversDefaultZone,
    plastiques: plastiquesDefaultZone,
    verre: verreDefaultZone,
    vegetaux: vegetauxDefaultZone
  };
  return newUserZoning;
}


/**
 *
 * @param zoningsMap
 * @param userZoning
 * @returns
 */
export const isUserZoningComplete = (zoningsMap:T_CITIES_ZONES,userZoning:T_ZONING|undefined):boolean => {

  // si on a deja pas de valeurs, renvoie true, pas d'alerte
  if (!zoningsMap || Object.keys(zoningsMap).length === 0)
    return true;

  // si le user n'a pas selectionne de ville, ou ville invalide, renvoie alerte
  if (!userZoning || !userZoning.city || (Object.keys(zoningsMap)).indexOf(userZoning.city) === -1) {
    console.log('Missing city selection');
    return false;
  }

  const userCityCollectTypes = zoningsMap[userZoning.city];

  const diversIsComplete = _isUserZoningOfCollectTypeIsComplete(userCityCollectTypes.divers,userZoning.divers);
  const plastiquesIsComplete = _isUserZoningOfCollectTypeIsComplete(userCityCollectTypes.plastiques,userZoning.plastiques);
  const verreIsComplete = _isUserZoningOfCollectTypeIsComplete(userCityCollectTypes.verre,userZoning.verre);
  const vegetauxIsComplete = _isUserZoningOfCollectTypeIsComplete(userCityCollectTypes.vegetaux,userZoning.vegetaux);

  return diversIsComplete && plastiquesIsComplete && verreIsComplete && vegetauxIsComplete;
}

/**
 *
 * @param collectTypeZoningMap
 * @param userCollectTypeZone
 * @returns
 */
const _isUserZoningOfCollectTypeIsComplete = (collectTypeZoningMap:T_CITY_ZONE|undefined,userCollectTypeZone:string|undefined):boolean => {

  // si pas de choix ou un seul choix => complete
  if (!collectTypeZoningMap || (Object.keys(collectTypeZoningMap)).length < 2)
    return true;

  const zonesKeys = Object.keys(collectTypeZoningMap);
  if (!userCollectTypeZone || zonesKeys.indexOf(userCollectTypeZone) === -1) {
    console.log('Missing divers selection',{current:userCollectTypeZone,zonesKeys});
    return false;
  }

  return true;
}



/**
 *
 * @param allDatas
 * @returns
 */
export const extractZoningsCities = (allDatas:T_CITIES_ALL_DATAS|undefined):T_CITIES_NAMES => {

  // si on a deja pas de valeurs, renvoie vide
  if (!allDatas || Object.keys(allDatas).length === 0) {
    console.log('GlobalService - extractZoningsCities - Cannot get zonings map, no embedded datas.');
    return {};
  }

  // liste des villes
  const citiesKeys = Object.keys(allDatas);

  let result:T_CITIES_NAMES = {};
  citiesKeys.forEach((cityKey) => {
    const cityDatas = allDatas[cityKey];
    result[cityKey] = cityDatas.labelCity;
  });
  return result;
}
