import React from 'react';
import useMetas from '../../datas/hooks/useMetas';

import Menu from "../../components/menu";
import Version  from "../../components/version";
import Document  from "../../components/document";

import {T_DOCUMENT} from '../../datas/contexts/state/interfaces';

import './documents.scss';

interface I_PROPS {
  userDocuments?: T_DOCUMENT[];
}

function Documents(props: I_PROPS) {

  // hook metas
  useMetas('Fiches SICTOM calendriers de collecte','Fiches pdf de ramassage des déchets');

  // valeurs récupérées du reducer state, placées en context, transmises via props
  const {userDocuments} = props;

  return (
    <div className="docs">

      <Menu />

      <div className="documents">
        <div className="titles">
          <h1>Documents</h1>
        </div>
        { userDocuments && userDocuments.length>0 ? (
        <div className="documents-list">
          { userDocuments.map((doc: T_DOCUMENT, index: number) => {
            return <Document key={index} document={doc} />;
          })}
        </div>
        ) : (
        <div className="empty-list">
        Aucun document trouvé.
        </div>
        )}
      </div>

      <Version />

    </div>
  );
}
export default Documents;
