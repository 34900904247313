import React from 'react';

import {T_CITY_ZONE} from '../../services/I_ZONINGS_MAP';

// import NextBlock  from "../nextBlock";
// import './NextBlock.scss';

interface I_PROPS {
  name?: string;  // collect type name "Divers"
  zones?: T_CITY_ZONE;
  userZoneSelected?: string;
  onZoneSelect?: any;
  suffix?: string;
}

function SelectCollectTypeZone({name,zones,userZoneSelected,onZoneSelect,suffix} : I_PROPS) {

  if (!zones || Object.keys(zones).length < 1)
    return null;

  return (
    <div className={'zone zone-'+suffix}>
      {name ? <div className="zone-type-label">{name}</div> : null}
      { Object.keys(zones).map((zoneKey:string) => {
        const labelZone = zones[zoneKey];
        const isSelected = userZoneSelected && userZoneSelected === zoneKey;
        return (
          <div key={zoneKey}
            className={'zoneModalButton mini '+suffix+'ZoneModalButton ' + (isSelected ? 'selected' : '')}
            onClick={(e) => onZoneSelect(zoneKey)}>
              { labelZone }
          </div>
        );
      })}
    </div>
  );
}
export default SelectCollectTypeZone;
