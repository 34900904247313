import ZoningModalButton from './ZoningModalButton';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';

// reducer actions
// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext                from '../../datas/contexts/GlobalContext';

// --- Services ----
import { extractZoningsMap, isUserZoningComplete } from '../../datas/helpers';

const MemoZoningModalButton = memo(ZoningModalButton);

const ComponentInContext = ({openModal}:{openModal:any}) => {

  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {loadingStatus,allDatas,userZoning,userDatas} = state;

  const zoningsMap = loadingStatus === 'loaded' ? extractZoningsMap(allDatas) : {};
  const isZoningComplete = loadingStatus === 'loaded' ? isUserZoningComplete(zoningsMap,userZoning) : false;

  return <MemoZoningModalButton
            loadingStatus={loadingStatus}
            selectedCityLabel={userDatas?.labelCity}
            isZoningComplete={isZoningComplete}
            openModal={openModal} />;
};

export default memo(ComponentInContext);
