import Component from './ModalZoning';

// memo() : Permet de ne pas re-render le composant si ses props n'ont pas changé
import {memo} from 'react';

// On a chargé les données dans un Context API depuis App
import {useContext} from 'react';
import GlobalContext from '../../datas/contexts/GlobalContext';

// --- Services ----
import { extractZoningsMap } from '../../datas/helpers';

const ComponentInMemo = memo(Component);

const ModalInfoInContext = ({onClickClose}:{onClickClose:Function}) => {
  // valeurs en context
  const {state} = useContext(GlobalContext);
  const {loadingStatus,allDatas,userZoning} = state;

  const zoningsMap = loadingStatus === 'loaded' ? extractZoningsMap(allDatas) : {};

  return <ComponentInMemo
            zoningsMap={zoningsMap}
            allDatas={allDatas}
            userZoning={userZoning}
            onClickClose={onClickClose} />;
};

export default memo(ModalInfoInContext);
